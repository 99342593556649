@use "./colors";

.footer {
  background-color: colors.$brown-400;
  margin-top: 2rem;

  svg {
    width: 3rem;
    height: 3rem;
  }

  &__content {
    margin: 0 auto;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 2rem;
    color: white;

    img {
      width: 3rem;
      height: 3rem;
    }

    &--address,
    &--social,
    &--contact {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 1rem auto;

      &__title {
        margin-bottom: 1rem;
        color: colors.$yellow-950;
      }
    }

    &--social {
      &__icons {
        display: flex;
        justify-content: space-around;
        width: 100%;
      }

      &__reservation {
        margin-top: 1rem;

        a:link,
        a:visited {
          font-size: 1.1rem;
          font-weight: bold;
          background-color: colors.$yellow-600;
          padding: 0 3rem;
          border-radius: 1.5rem;
          position: relative;
          display: block;
          z-index: 1;
          overflow: hidden;
          transition: all 0.3s ease-in-out;
          height: 4rem;
          line-height: 4rem;
        }

        a::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 0;
          background-color: orange;
          border-radius: inherit;
          z-index: -1;
          transition: height 0.3s ease-in-out;
          transform: translate(-50%, -50%) rotate(45deg);
        }

        a:hover::before,
        a:active::before {
          height: 550%;
        }

        a:hover,
        a:active {
          color: black;
        }
      }
    }

    &--contact {

      &__whatsapp,
      &__email {
        display: flex;
        align-items: center;
      }
    }
  }
}