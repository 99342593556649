@use "./colors.scss";

.contacts {
  max-width: 1440px;
  margin: 0 auto;

  &__form {
    margin: 0 auto 2rem;
    box-shadow: rgba(141, 54, 14, 0.322) 0px 54px 55px, rgba(155, 111, 16, 0.377) 0px -12px 30px,
      rgba(0, 0, 0, 0.486) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(200, 214, 73, 0.09) 0px -3px 5px;
    border-radius: 1rem;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    width: 500px;

    @media (max-width: 768px) {
      width: 80vw;
    }

    label {
      margin: 0.5rem 0;
    }

    input {
      padding: 0.4rem;
      border-radius: 1rem;
      border: 1px solid rgba(0, 0, 0, 0.555);
      background-color: rgba(49, 89, 112, 0.178);
      width: 100%;
    }

    input:hover,
    input:focus {
      background-color: rgba(49, 89, 112, 0.37);
    }

    &--dates {
      padding: 1rem 0;

      input {
        margin: 0.5rem 0;
      }
    }

    textarea {
      border-radius: 1rem;
      padding: 0.5rem;
    }

    &--errorMessage {
      color: red;
      font-size: 0.8rem;
    }

    &--button {
      width: 10rem;
      height: 2rem;
      margin: 1rem auto;
      border-radius: 1rem;
      font-size: 1.2rem;
      cursor: pointer;
      background-color: rgb(252, 202, 136);

      &:disabled {
        cursor: not-allowed;
      }
    }

    &--required {
      font-size: 0.9rem;
    }
  }

  &__placeholder,
  &__success,
  &__error {
    height: 2.5rem;
    border-radius: 2rem;
    width: 16rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin-left: 1rem;
    }
  }

  &__success {
    background-color: rgb(21, 204, 21);
  }

  &__error {
    background-color: rgb(223, 97, 75);
  }
}

.spinner {
  display: inline-block;
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-left-color: #22a6b3;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  animation: spin 1s linear infinite;
  margin-left: 1rem;
}

.the-count {
  float: right;
  padding: 0.1rem 0 0 0;
  font-size: 0.875rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}