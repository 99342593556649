// These styles overwrite the ones that have inside the Swiper package

.swiper {
  margin: 0 auto;
  width: calc(100vw - 8rem);
  height: 85vh;
  margin-bottom: 2rem;
  max-width: 1440px;

  @media (max-width: 550px) {
    height: 70vh;
    width: calc(100vw - 4rem);
  }

  @media (max-width: 450px) {
    height: 50vh;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;

  -webkit-justify-content: center;
  justify-content: center;

  -webkit-box-align: center;
  -ms-flex-align: center;

  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 1rem;
  color: black;
  font-weight: bold;
  opacity: 1;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.322);
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}