.about {
  &__video {
    display: flex;
    justify-content: center;
  }

  &__text {
    padding: 0 5rem 2rem;

    @media (max-width: 768px) {
      padding: 0 2rem 2rem;
    }
    
    p {
      text-align: justify;
      line-height: 1.5rem;
    }
  }
}