@use './colors';

.welcome {
  h1 {
    color: colors.$title;
    font-size: 3rem;
    margin-bottom: 2rem;
  }

  // font-family: 'Pacifico', cursive;
  font-family: 'Satisfy',
  cursive;
  text-align: center;
  margin: 1rem auto 0;
  max-width: 800px;
  padding: 1rem;

  p {
    color: colors.$title;
    font-size: 1.2rem;
    font-weight: bold;
  }
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  max-width: 1440px;
  margin: 0 auto;
  padding: 4rem;
  margin-top: 2rem;

  .card {

    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    padding: 0 2rem 4rem;
    text-align: center;

    &-1 {
      background-color: colors.$orange-900;
    }

    &-2 {
      background-color: colors.$lime-900;
    }

    &-3 {
      background-color: colors.$brown-400;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    img {
      margin-top: -3rem;
      border-radius: 50%;
      width: 10rem;
      height: 10rem;
    }

    h2,
    p {
      color: white;
    }

    @media (max-width: 800px) {
      flex-direction: row;
      padding: 1rem;

      img {
        margin-top: 0rem;
        width: 8rem;
        height: 8rem;
      }
    }

    @media (max-width: 540px) {
      gap: 1rem;

      img {
        width: 6rem;
        height: 6rem;
      }
    }

    @media (max-width: 425px) {
      flex-direction: column;

      img {
        margin-top: 0rem;
      }
    }
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    padding: 2rem;
  }
}