@use "./colors";

.presentation {
  height: calc(100vh - 3.7rem);
  background-image: url("../assets/presentation.jpg");
  background-position: center;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: 100vh;
  }

  h1,
  h2,
  h3 {
    color: colors.$title
  }

  &__content {
    background-color: colors.$content-bg;
    padding: 7rem 8rem;

    h1 {
      font-weight: 700;
      font-size: 4rem;
      text-shadow: 2px 2px 3px #000000;

      &::after {
        content: "";
        margin: 0 auto;
        display: block;
        height: 0.5rem;
        width: 50%;
        bottom: 0;
        right: 0;
        background-color: colors.$title-line;
      }
    }

    h2,
    h3 {
      margin-top: 1rem;
      font-weight: 900;
    }

    @media (max-width: 768px) {
      padding: 5rem 2rem;

      h1 {
        font-size: 3rem;
      }
    }
  }
}