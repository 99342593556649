$nav-bg: linear-gradient(90deg, #5f370e 0%, rgba(151, 90, 22, 0.81) 52.85%, rgba(101, 43, 25, 0.81) 99.48%);
$content-bg: hsla(0, 0%, 77%, 0.479);
$title: #441d11;
$title-line: #2c130b;

$brown-400: #7e5232;

$yellow-200: #fef08a;
$yellow-400: #facc15;
$yellow-500: #eab308;
$yellow-600: #ca8a04;
$yellow-900: #713f12;
$yellow-950: #422006;

$lime-900: #365314;

$orange-900: #7c2d12;

/*
$yellow-200: #3f3311;
$yellow-400: #79622275;
$yellow-500: #8b6712;
$yellow-600: #c49e35;
$yellow-950: #f8d77c;
$yellow-900: #FFCE45;
*/