.town {
  max-width: 1440px;
  margin: 0 auto;
  padding: 2rem;

  img {
    float: left;
    width: 300px;
    padding-right: 2rem;
    padding-bottom: 2rem;
  }
}