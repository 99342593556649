.store {
  max-width: 1440px;
  margin: 0 auto;
  h2 {
    margin: 1rem 0;
  }
  &__text {
    padding: 0 5rem 2rem;

    @media (max-width: 768px) {
      padding: 0 2rem 2rem;
    }
    
    p {
      line-height: 1.5rem;
    }

    ul {
      li{
        list-style-type: disc;
      }

      li:not(:first-child) {
        margin-top: 0.5rem;
      }
    }
  }
}