@use "./colors";

.location {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &--card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    gap: 1rem;
    padding: 1rem 6rem;
    background-color: colors.$yellow-950;
    color: white;
    height: 100%;

    img {
      border-radius: 50%;
      width: 8rem;
      height: 8rem;
    }

    @media (max-width: 1040px) {
      padding: 1rem 3rem;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

}