@use "./colors";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  @media (max-width: 768px) {
    font-size: 93.75%; //15 px
  }

  @media (max-width: 425px) {
    font-size: 87.5%; // 14px
  }
}

li {
  list-style: none;
}

a:link,
a:visited {
  text-decoration: none;
  color: white;
  font-size: 0.8rem;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;
}

h1,
h2 {
  // color: colors.$title;
  text-align: center;
}

p {
  font-size: 1rem;
}

.global_titles {
  color: colors.$title;
  padding: 2rem 0;
  font-size: 2rem;
}